import { HStack, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { EndpointStatus, StatusSummaryProps } from '../models';
import { useEffect, useState } from 'react';
import { analyzeItemStatus, formatToFourDecimals } from 'utilities';

function StatusSummary({ title, data }: StatusSummaryProps) {
  const [unhealthyItems, setUnhealthyItems] = useState<typeof data>([]);

  const statusAnalysis = analyzeItemStatus(data);

  const headingColor = useColorModeValue('black', 'white');

  const getBgColor = (healthyPercentage: number) => {
    return healthyPercentage === 100 ? 'green.300' : 'red.300';
  };

  const bgColor = getBgColor(statusAnalysis.healthyPercentage);

  const getCoordinatorBalance = (data: any[], environment: string) => {
    const coordinator = data
      .filter((attestor) => attestor.environment === environment)
      .find((attestor) => attestor.data?.attestor?.is_coordinator);

    if (!coordinator) return <Text color={'black'}>No coordinator found</Text>;

    return (
      <VStack align="start" spacing={2} w={'100%'}>
        <HStack w={'100%'} justifyContent={'space-between'}>
          <Text color={'black'}>Name</Text>
          <Text color={'black'}>Balance / Min-Balance</Text>
        </HStack>
        {coordinator.data.evm_network_info.map((network: any) => (
          <HStack key={network.network.name} justifyContent={'space-between'} w={'100%'}>
            <Text color={'black'}>{network.network.name}</Text>
            <Text color={'black'}>
              {environment !== 'devnet'
                ? `${formatToFourDecimals(parseFloat(network.balance))} / ${formatToFourDecimals(parseFloat(network.min_balance))}`
                : 'N/A'}
            </Text>
          </HStack>
        ))}
      </VStack>
    );
  };

  useEffect(() => {
    const currentUnhealthyItems = data.filter((item) => item.status !== EndpointStatus.HEALTHY);
    setUnhealthyItems(currentUnhealthyItems);
  }, [data]);

  return (
    <HStack align="start" w="100%" justifyContent={'center'}>
      <VStack
        bg={bgColor}
        w={'100%'}
        p={'25px'}
        border="1px"
        borderRadius="md"
        borderColor={headingColor}
        spacing={'20px'}
      >
        <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} color={'black'}>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Heading>
        <HStack>
          <Text color={'black'} fontSize={'x-large'} fontWeight={'800'}>
            {statusAnalysis.total}
          </Text>
          <Text color={'black'}>total items</Text>
        </HStack>
        <HStack w={'100%'} justifyContent={'space-between'}>
          <HStack>
            <Text color={'black'}>{statusAnalysis.healthy}</Text>
            <Text color={'black'}>healthy items</Text>
          </HStack>
          <HStack>
            <Text color={'black'}>{statusAnalysis.healthyPercentage}</Text>
            <Text color={'black'}>%</Text>
          </HStack>
        </HStack>
        <HStack w={'100%'} justifyContent={'space-between'}>
          <HStack>
            <Text color={'black'}>{statusAnalysis.unhealthy}</Text>
            <Text color={'black'}>unhealthy items</Text>
          </HStack>
          <HStack>
            <Text color={'black'}>{statusAnalysis.unhealthyPercentage}</Text>
            <Text color={'black'}>%</Text>
          </HStack>
        </HStack>
        <HStack w={'100%'} justifyContent={'space-between'}>
          {title === 'devnet' ? null : getCoordinatorBalance(data, title.toLowerCase())}{' '}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default StatusSummary;
