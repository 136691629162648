import { Network } from 'dlc-btc-lib/models';

export enum EndpointType {
  ATTESTOR = 'attestor',
  SITE = 'site',
}

export interface EndpointInfo {
  type: EndpointType;
  name: string;
  url: string;
}

export enum EndpointStatus {
  ERROR = 'error',
  UNREACHABLE = 'unreachable',
  HEALTHY = 'healthy',
  UNHEALTHY = 'unhealthy',
}

export type ResponseDataMap = {
  [key in EndpointType]: ResponseData[];
};

export interface ResponseData {
  endpoint: EndpointInfo;
  data: any;
  error: string | null;
  status: EndpointStatus;
}

// export interface DLCHealthReport {
//   subchain: string;
//   uuid: string;
//   oracle: string;
//   onChain: string;
//   isTransitory: boolean;
//   isHealthy: boolean;
//   lastEventAge: number;
// }

export interface GeneralStatusTableProps {
  title: EndpointType;
  data: ResponseData[];
  openModal: (item: ResponseData, type: EndpointType) => void;
}

export interface DetailsModalProps {
  type: EndpointType | AttestorEnvironment;
  // selectedEndpoint: ResponseData | AttestorReport;
  selectedEndpoint: AttestorReport;
  isOpen: boolean;
  onClose: () => void;
}

export interface EndpointStatusProps {
  status: EndpointStatus;
}

// Attestors

export interface AttestorReport {
  operator: string;
  version: string;
  environment: AttestorEnvironment;
  domain: string;
  data: AttestorHealthResponse | null;
  name: string | null;
  canTalkToBitcoin?: boolean;
  evmHealth?: boolean;
  canTalkOverNoise?: boolean;
  VSR: boolean | undefined;
  status: EndpointStatus;
  error: string | null;
}

export enum AttestorEnvironment {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
  DEVNET = 'devnet',
}

export type AttestorReportDataMap = {
  [key in AttestorEnvironment]: AttestorReport[];
};

export interface AttestorStatusTableProps {
  title: AttestorEnvironment;
  data: AttestorReport[];
  openModal: (item: AttestorReport, type: AttestorEnvironment) => void;
}

export interface StatusSummaryProps {
  title: AttestorEnvironment;
  data: AttestorReport[];
}

// Attestor health response

export interface AttestorHealthResponseWrapped {
  data: AttestorHealthResponse[];
}

export interface AttestorHealthResponse {
  version: string;
  attestor: Attestor;
  can_talk_to_bitcoin: boolean;
  can_talk_over_noise: boolean;
  message: string;
  status: string;
  threshold_handler_health_data: ThresholdHandlerHealthData;
  verifying_shares: any[];
  evm_network_info: EVMNetworkInfo[];
  balance: string;
}

export interface EVMNetworkInfo {
  address: string;
  block_number: number;
  network: {
    chain_id: string;
    name: string;
  };
  is_reachable: boolean;
  balance: string;
  min_balance: string;
}

export interface Attestor {
  bi_handler: BiHandler;
  bitcoin_network: string;
  esplora_api_url: string;
  is_coordinator: boolean;
  name: string;
  num_recent_event_to_sync: number;
  required_btc_confirmations: number;
}

export interface BiHandler {
  tss_commitment_chain: string;
  blockchain_interface_url: string;
}

export interface ThresholdHandlerHealthData {
  identifier: string;
  participants: any[];
  threshold_data: ThresholdData;
}

export interface ThresholdData {
  coordinator: Participant;
  coordinator_pubkey: string;
  new_threshold: number;
  old_threshold: number;
  participant_to_add: Participant;
  participants: Participant[];
}

export interface Participant {
  domain: string;
  name: string;
}

export type EVMChainName =
  | 'mainnet'
  | 'arbitrum'
  | 'base'
  | 'sepolia'
  | 'arbitrum-sepolia'
  | 'base-sepolia'
  | 'avax'
  | 'bnb'
  | 'holesky';

export type XRPLChainName = 'ripple-xrpl-mainnet' | 'ripple-xrpl-testnet';

export type ChainName = EVMChainName | XRPLChainName;

type ChainType = 'evm' | 'xrpl';

export type ChainEnvironment = 'MAINNET' | 'TESTNET' | 'DEVNET';

export interface ChainConfigData {
  type: ChainType;
  network: string;
  providerURL: string;
  issuerAddress?: string;
  environment: ChainEnvironment;
  explorerUrl?: string;
  iBTCContractAddress?: string;
  dlcManagerContractAddress?: string;
  bitcoinBlockchainAPIURL: string;
  extendedAttestorGroupPublicKey: string;
  bitcoinNetwork: Network;
}

export interface NetworkHealthCheckProps {
  networks: EVMNetworkInfo[];
  chainConfigData: ChainConfigData[];
  isCoordinator: boolean;
}
